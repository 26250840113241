export class InjectService {
  static insertStyles(url: string) {
    const css = document.createElement('link')
    css.rel = 'stylesheet'
    css.type = 'text/css'
    css.href = url

    document.querySelector('head')?.appendChild(css)
  }
}
