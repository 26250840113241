export enum SupportedLanguages {
  BG = 'bg',
  CS = 'cs',
  DE = 'de',
  EN = 'en',
  FI = 'fi',
  FR = 'fr',
  ES = 'es',
  HU = 'hu',
  IT = 'it',
  NL = 'nl',
  NO = 'no',
  RO = 'ro',
  SV = 'sv',
  PL = 'pl',
  PT = 'pt',
  TR = 'tr',
  EL = 'el',
  UK = 'uk',
}
