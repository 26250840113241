interface IframeServiceConstructor {
  url: string
  language: string
}

export class IframeService {
  protected url: string
  protected language: string

  constructor({ url, language }: IframeServiceConstructor) {
    this.url = url
    this.language = language
  }

  render() {
    const iframeElement = document.createElement('iframe')
    const localisedUrl = new URL(this.language, this.url).href
    iframeElement.setAttribute('src', localisedUrl)
    iframeElement.setAttribute('id', 'iframe-element')

    iframeElement.classList.add('hidden')

    return iframeElement
  }
}
