import { SupportedLanguages } from '../enums/supported-languages.enum'

const isSupportedLanguage = (
  language: unknown
): language is SupportedLanguages => {
  return (
    typeof language === 'string' &&
    Object.values(SupportedLanguages).includes(language as SupportedLanguages)
  )
}

export const getSupportedLanguageOrDefault = (
  language: unknown
): SupportedLanguages => {
  return isSupportedLanguage(language) ? language : SupportedLanguages.EN
}
