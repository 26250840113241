import './scss/style.scss'

import { chatbotUrl, pluginCdnUrl } from './constants/environment'
import { getSupportedLanguageOrDefault } from './helpers/language.helper'
import { ButtonService } from './services/button.service'
import { IframeService } from './services/iframe.service'
import { InjectService } from './services/inject.service'
import { PluginService } from './services/plugin.service'
import { TextboxService } from './services/textbox.service'

const container = document.querySelector(
  '.chatbot-plugin-container'
) as HTMLDivElement
const language = getSupportedLanguageOrDefault(
  container.getAttribute('data-language')
)

const userId = container.getAttribute('data-user-id')
const hideBubble = container.getAttribute('data-hide-bubble') === 'true'

try {
  InjectService.insertStyles(`${pluginCdnUrl}style.css`)

  const buttonElement = new ButtonService({
    language,
    userId,
    hideBubble,
  }).render()

  const iframeElement = new IframeService({
    url: chatbotUrl,
    language,
  }).render()
  const textBoxElement = new TextboxService({ language }).render()
  const pluginService = new PluginService({ iframeElement })

  container.innerHTML = ''
  container.append(buttonElement)
  container.append(iframeElement)

  if (!hideBubble) {
    setTimeout(() => {
      container.append(textBoxElement)
    }, 2000)
  }

  window.chatbotPlugin = pluginService
} catch (error) {
  // eslint-disable-next-line no-console
  console.error(error)
  container.innerHTML = ''
}
