import { vmProfilePicUrl } from '../constants/vm-profile-pic.constant'
import { MixpanelEventNames } from '../enums/mixpanel-event-names.enum'
import { SupportedLanguages } from '../enums/supported-languages.enum'
import { MixpanelService } from './mixpanel.service'

interface ButtonServiceConstructor {
  language: SupportedLanguages
  userId: string | null
  hideBubble: boolean
}

export class ButtonService {
  protected userId: string | null
  protected language?: SupportedLanguages
  protected hideBubble: boolean

  constructor({ userId, language, hideBubble }: ButtonServiceConstructor) {
    this.userId = userId
    this.language = language
    this.hideBubble = hideBubble
  }

  render() {
    if (this.hideBubble) {
      return ''
    }

    const button = document.createElement('button')
    button.style.backgroundImage = `url(${vmProfilePicUrl})`
    button.onclick = () => {
      MixpanelService.track(MixpanelEventNames.BubbleButtonClick)

      const element = document.getElementById('iframe-element')
      if (!element) {
        return
      }

      element.classList.remove('hidden')
    }

    const highlighter = document.createElement('div')
    highlighter.className = 'bubble-highlighter'

    button.appendChild(highlighter)

    const div = document.createElement('div')
    div.className = 'bubble-container'
    div.appendChild(button)

    return div
  }
}
