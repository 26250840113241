import { chatbotUrl } from '../constants/environment'
import { MessageEventTypes } from '../enums/message-event-types.enum'

interface PluginServiceConstructor {
  iframeElement: HTMLIFrameElement
}

export class PluginService {
  protected iframeElement: HTMLIFrameElement

  constructor({ iframeElement }: PluginServiceConstructor) {
    this.iframeElement = iframeElement
  }

  open() {
    if (this.iframeElement) {
      this.iframeElement.classList.remove('hidden')
    }
  }

  close() {
    if (this.iframeElement) {
      this.iframeElement.classList.add('hidden')
    }
  }

  login(userId: string) {
    if (this.iframeElement) {
      this.iframeElement.contentWindow?.postMessage(
        { type: MessageEventTypes.USER_LOGGED_IN, userId },
        chatbotUrl
      )
    }
  }
}
