import { MixpanelEventNames } from '../enums/mixpanel-event-names.enum'
import { SupportedLanguages } from '../enums/supported-languages.enum'
import { getTranslatedText } from '../helpers/get-language-translation.helper'
import { MixpanelService } from './mixpanel.service'

interface TextboxServiceConstructor {
  language: SupportedLanguages
}

export class TextboxService {
  protected language: SupportedLanguages

  constructor({ language }: TextboxServiceConstructor) {
    this.language = language
  }

  render() {
    const existingTextBox = document.getElementById('textbox-container')
    if (existingTextBox) {
      return existingTextBox
    }

    const textBox = document.createElement('div')
    textBox.id = 'textbox-container'
    textBox.innerHTML = `
    <p>
        <strong>${getTranslatedText(this.language, 'chatbot_bubble_textbox_headline')}</strong>
    </p>
    <p>
        ${getTranslatedText(this.language!, 'chatbot_bubble_textbox_text')}
    </p>
    <p class="text-muted">
        ${getTranslatedText(this.language!, 'chatbot_bubble_textbox_cta')}
    </p>
    `
    textBox.onclick = () => {
      MixpanelService.track(MixpanelEventNames.BubbleButtonTextBoxClick)
    }

    const closeIcon = document.createElement('div')
    closeIcon.className = 'close-cta'
    closeIcon.innerHTML = 'X'

    closeIcon.onclick = event => {
      event.stopPropagation()
      event.preventDefault()

      const element = document.getElementById('textbox-container')
      if (!element) {
        return
      }

      element.classList.add('hidden')
    }

    textBox.prepend(closeIcon)

    return textBox
  }
}
