import bgTranslation from '../../locales/bg.json'
import csTranslation from '../../locales/cs.json'
import deTranslation from '../../locales/de.json'
import elTranslation from '../../locales/el.json'
import enTranslation from '../../locales/en.json'
import esTranslation from '../../locales/es.json'
import fiTranslation from '../../locales/fi.json'
import frTranslation from '../../locales/fr.json'
import huTranslation from '../../locales/hu.json'
import itTranslation from '../../locales/it.json'
import nlTranslation from '../../locales/nl.json'
import noTranslation from '../../locales/no.json'
import plTranslation from '../../locales/pl.json'
import ptTranslation from '../../locales/pt.json'
import roTranslation from '../../locales/ro.json'
import svTranslation from '../../locales/sv.json'
import trTranslation from '../../locales/tr.json'
import ukTranslation from '../../locales/uk.json'
import { SupportedLanguages } from '../enums/supported-languages.enum'

type SupportedLanguagesTranslation = {
  [key: string]: string
}

type TranslationDictionary = Record<
  SupportedLanguages,
  SupportedLanguagesTranslation
>

const translationData: TranslationDictionary = {
  bg: bgTranslation,
  cs: csTranslation,
  de: deTranslation,
  en: enTranslation,
  es: esTranslation,
  fi: fiTranslation,
  fr: frTranslation,
  hu: huTranslation,
  it: itTranslation,
  nl: nlTranslation,
  no: noTranslation,
  pl: plTranslation,
  pt: ptTranslation,
  ro: roTranslation,
  sv: svTranslation,
  tr: trTranslation,
  el: elTranslation,
  uk: ukTranslation,
}

const DEFAULT_LANGUAGE: SupportedLanguages = SupportedLanguages.EN

export const getTranslatedText = (language: string, key: string): string => {
  const lang = Object.values(SupportedLanguages).includes(
    language as SupportedLanguages
  )
    ? (language as SupportedLanguages)
    : DEFAULT_LANGUAGE

  const translations = translationData[lang]

  return translations[key] ?? key
}
