import { chatbotUrl } from '../constants/environment'
import { MessageEventTypes } from '../enums/message-event-types.enum'
import { SupportedLanguages } from '../enums/supported-languages.enum'
import { getSupportedLanguageOrDefault } from '../helpers/language.helper'
import { Brand } from '../types/brand.type'
import { ButtonService } from './button.service'
import { IframeService } from './iframe.service'
import { MixpanelService } from './mixpanel.service'
import { TextboxService } from './textbox.service'

export class PluginService {
  private container!: HTMLDivElement
  private iframeElement!: HTMLIFrameElement
  private mixpanelScreen!: string | null
  private language!: SupportedLanguages
  private hideBubble!: boolean
  public userId!: string | null

  constructor() {
    this.init()
  }

  init() {
    this.container = document.querySelector(
      '.chatbot-plugin-container'
    ) as HTMLDivElement

    this.language = getSupportedLanguageOrDefault(
      this.container.getAttribute('data-language')
    )
    this.userId = this.container.getAttribute('data-user-id')
    this.hideBubble = this.container.getAttribute('data-hide-bubble') === 'true'
    this.mixpanelScreen = this.container.getAttribute('data-mixpanel-screen')
  }

  open(eventName?: string) {
    if (eventName) {
      MixpanelService.track(eventName, {
        ...(this.mixpanelScreen && { 'Screen Name': this.mixpanelScreen }),
      })
    }
    this.iframeElement?.classList.remove('hidden')
  }

  isOpen() {
    return !this.iframeElement?.classList.contains('hidden')
  }

  close() {
    this.iframeElement?.classList.add('hidden')
  }

  setUserId(userId: string) {
    this.iframeElement?.contentWindow?.postMessage(
      { type: MessageEventTypes.SET_USER_ID, payload: userId },
      chatbotUrl
    )
  }

  setUserBrand(brand: Brand) {
    this.iframeElement?.contentWindow?.postMessage(
      { type: MessageEventTypes.SET_USER_BRAND, payload: brand },
      chatbotUrl
    )
  }

  render() {
    this.iframeElement = new IframeService({
      url: chatbotUrl,
      language: this.language,
    }).render()

    const buttonElement = this.hideBubble ? '' : new ButtonService().render()

    this.container.innerHTML = ''
    this.container.append(buttonElement)
    this.container.append(this.iframeElement)

    if (!this.hideBubble) {
      const textBoxElement = new TextboxService({
        language: this.language,
      }).render()
      this.container.append(textBoxElement)
    }

    window.addEventListener('message', event => {
      switch (event.data?.type) {
        case MessageEventTypes.IFRAME_READY:
          this.setUserId(this.userId || '')
          break

        case MessageEventTypes.CLOSE_CHATBOT:
          this.close()
          break

        case MessageEventTypes.CHATBOT_MIXPANEL_TRACKING:
          MixpanelService.track(event.data.uba, {
            ...(event.data?.properties || {}),
            'Screen Name': this.mixpanelScreen,
            'User Message Count': event.data.properties?.userMessageCount,
          })
          break
      }
    })
  }
}
