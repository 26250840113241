import './scss/style.scss'

import { pluginCdnUrl } from './constants/environment'
import { InjectService } from './services/inject.service'
import { PluginService } from './services/plugin.service'

const initialize = () => {
  try {
    InjectService.insertStyles(`${pluginCdnUrl}style.css`)

    window.chatbotPlugin = new PluginService()
    window.chatbotPlugin.render()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const checkIfDivAvailable = () => {
  if (document.querySelector('.chatbot-plugin-container')) {
    initialize()
  } else {
    setTimeout(checkIfDivAvailable, 300)
  }
}

checkIfDivAvailable()
